 @import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic);
    @import url('https://fonts.googleapis.com/css2?family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');


.hidden-sm {
  display: none;
}
.invoice-box {
  background: #ffffff;
  max-width: 1000px;
  margin: 60px auto;
  padding: 30px;
  /* border: 1px solid #002336; */
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);*/
  font-size: 16px;
  line-height: 24px;
  color: #002336;
}
.title {
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  /* border-bottom: 1px solid #8B8B8B; */
  margin-bottom: 4px;
}
.infoblock {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
  padding-top: 0px;
}
.titles {
  padding-top: 4px;
  margin-top: 20px;
  background: #DCDCDC;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }
  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

p.product-detail {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
/** RTL **/

.rtl {
  direction: rtl;
  font-family: "Lato", Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
}
.rtl table {
  text-align: right;
}
.rtl table tr td:nth-child(2) {
  text-align: left;
}
.eqWrap {
  display: flex;
}
.eq {
  padding: 10px;
}
.item:nth-of-type(odd) {
  background: #F9F9F9!important;
}
.item:nth-of-type(even) {
  background: #fff!important;
}
.equalHW {
  flex: 1;
}
.equalHM {
  width: 32%;
}
.equalHMR {
  width: 32%;
  margin-bottom: 2%;
}
table.table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}
.table th, .table td {
  text-align: left;
  padding: 0.25em;
}

.inv-button:hover {
  box-shadow: 0 0 4px rgba(3, 3, 3, 0.8);
  opacity: 0.9;
}