.timeline {
          	 position: relative;
          	 padding-left: 4rem;
          	 margin: 0 0 0 30px;
          	 color: white;
          }
           .timeline:before {
          	 content: '';
          	 position: absolute;
          	 left: 0;
          	 top: 0;
          	 width: 4px;
          	 height: 100%;
          	 background: #f5f5f5;
          }
           .timeline .timeline-container {
          	 position: relative;
          	 margin-bottom: 2.5rem;
          }
          .timeline-body p {
              color: #838383;
              margin-bottom: 0;
          }
           .timeline .timeline-container .timeline-icon {
          	 position: absolute;
          	 left: -88px;
          	 top: 4px;
          	 width: 50px;
          	 height: 50px;
          	 border-radius: 50%;
          	 text-align: center;
          	 font-size: 1.25rem;
          	 background: #4f537b;
          }
           .timeline .timeline-container .timeline-icon i {
          	 position: absolute;
          	 left: 50%;
          	 top: 50%;
          	 transform: translate(-50%, -50%);
          }
           .timeline .timeline-container .timeline-icon img {
          	 width: 100%;
          	 height: 100%;
          	 border-radius: 50%;
          }
           .timeline .timeline-container .timeline-body {
          	 background: #f5f5f5;
          	 border-radius: 3px;
          	 padding: 10px 15px;
          	 box-shadow: 1px 3px 9px rgba(0, 0, 0, .1);
          }
           .timeline .timeline-container .timeline-body:before {
          	 content: '';
          	 background: inherit;
          	 width: 20px;
          	 height: 20px;
          	 display: block;
          	 position: absolute;
          	 left: -10px;
          	 transform: rotate(45deg);
          	 border-radius: 0 0 0 2px;
          }
           .timeline .timeline-container .timeline-body .timeline-title {
          	 margin-bottom: 0.5rem;
          }
           .timeline .timeline-container .timeline-body .timeline-title .badge {
          	 background: #4f537b;
          	 padding: 4px 8px;
          	 border-radius: 3px;
          	 font-size: 12px;
          	 font-weight: bold;
          }
           .timeline .timeline-container .timeline-body .timeline-subtitle {
          	 font-weight: 300;
          	 font-style: italic;
          	 opacity: 0.6;
          	 margin-top: 8px;
          	 font-size: 12px;
             color: #9c9c9c;
          }
           .timeline .timeline-container.primary .badge, .timeline .timeline-container.primary .timeline-icon {
          	 background: #1d8cf8 !important;
          }
           .timeline .timeline-container.info .badge, .timeline .timeline-container.info .timeline-icon {
          	 background: #11cdef !important;
          }
           .timeline .timeline-container.success .badge, .timeline .timeline-container.success .timeline-icon {
          	 background: #00bf9a !important;
          }
           .timeline .timeline-container.warning .badge, .timeline .timeline-container.warning .timeline-icon {
          	 background: #ff8d72 !important;
          }
           .timeline .timeline-container.danger .badge, .timeline .timeline-container.danger .timeline-icon {
          	 background: #fd5d93 !important;
          }