/* CustomScrollbar.css */

/* WebKit-based browsers (Chrome, Safari) */
/* Customize the scrollbar track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Customize the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #0736d0;
  border-radius: 10px;
}

/* Firefox */
/* Customize the scrollbar track and thumb */
/* For Firefox, we use scrollbar-width */
/* Set width and background-color for the scrollbar track */
/* Set width and background-color for the scrollbar thumb */
/* Set border-radius for the scrollbar thumb */
/* Add any other desired styles */
html {
  scrollbar-width: thin;
  /* scrollbar-color: #fff #dfd8d8; */
}

/* For a better cross-browser experience, you can add the following */
/* Hide the scrollbar in IE and Edge */
/* Show a thin scrollbar in Firefox */
/* Show a normal scrollbar in WebKit-based browsers */
/* Adjust the colors and other properties as needed */
/* This is just an example and may require further tweaking based on your specific design */
/* This may not be supported in the future, so always test thoroughly */
/* For best results, consider using a library or plugin that provides better cross-browser support */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
  /* #e7e7e7; */
}

.orders-scrollbar::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  background-color: transparent
  /* #e7e7e7 !important; */
}

@media only screen and (max-width: 720px) {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #cecece;
  border-radius: 10px;
}

/* If you want to hide the scrollbar when it's not in use */
/* You can add the following to hide the scrollbar when the user is not actively scrolling */
/* Note that this might not work on all browsers, and it's purely for cosmetic purposes */
/* Adjust as needed based on your specific design */
.custom-scrollbar::-webkit-scrollbar-thumb {
  opacity: 0.8;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  opacity: 1;
}

.App {
  text-align: center;
}

td {
  vertical-align: middle;
  font-size: 14px;
}
.mr-2 {
  margin-right: 0.75rem !important;
}
.prod-title {
  font-size: 16px;
  font-weight: 600;
  color: #03a9f4 !important;
}
.prod-desc {
  font-size: 13px;
  color: darkgrey;
}
.pb-300 {
  padding-bottom: 300px;
}
.total {
  background: linear-gradient(45deg, #ffc107, #ff9800);
  font-size: 12px;
  padding: 2px 5px;
  color: #fff;
  border-radius: 3px;
  /* border: 1px solid #ff9800; */
  margin-right: 0.5rem;
}
.nk-counter span {
  color: #2b5cfd;
  font-size: 14px;
  line-height: normal;
}

@keyframes slide-down {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(50vh);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-500%);
  }
}

.btn-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") !important;
}

@keyframes moveToCart {
  from {
    transform: translate(0, 0);
    transform: scale(3);
  }
  to {
    transform: translate(100px, -700px);
    transform: scale(1);
  }
}

@keyframes moveProductToCart {
  0% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
}

.tp-header-action-badge {
  position: absolute;
  top: 15px;
  /* right: -13px; */
  display: inline-block;
  width: 23px;
  height: 23px;
  /* line-height: 20px; */
  text-align: center;
  border-radius: 50%;
  background-color: black;
  color: white;
  font-size: 12px;
  font-weight: 700;
  border: 2px solid white;
}

.sidebar {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.profile-icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
}

.user-details {
  text-align: center;
  margin-top: 10px;
}

.tab-icon {
  margin-right: 10px;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  /* position: relative !important; */
  /* top: 100px !important; */
  height: auto !important;
  left: auto !important;
  z-index: 999 !important;
  width: 100% !important;
}

.css-1l8j5k8 {
  /* top: 100px !important; */
  height: auto !important;
  left: auto !important;
  z-index: 999 !important;
  width: 100% !important;
}
.css-1191obr-MuiPaper-root-MuiAppBar-root {
  /* position: relative !important; */
  /* top: 100px !important; */
  width: 100% !important;
}

.css-19z1ozs-MuiPaper-root-MuiAppBar-root {
  top: 100px !important;
  left: auto !important;
}

.css-k008qs {
  position: relative !important;
  top: 100px !important;
}

.css-1r9jet7 {
  min-height: 15px !important;
  justify-content: flex-start !important;
}

.css-10hburv-MuiTypography-root {
  font-size: 0.75rem !important;
  font-weight: bold !important;
}

.css-100u9pv-MuiDrawer-docked .MuiDrawer-paper {
  overflow-x: hidden !important;
}

@media only screen and (max-width: 1024px) {
  .css-1ibjhk8 {
    top: 700px !important;
    position: absolute !important;
    left: 0px !important;
    padding: 0px !important;
    width: 100%;
  }
}

@media only screen and (max-width: 720px) {
  .css-100u9pv-MuiDrawer-docked {
    height: fit-content !important;
    width: 100% !important;
  }

  .css-100u9pv-MuiDrawer-docked .MuiDrawer-paper {
    width: 100% !important;
    height: fit-content !important;
    position: absolute !important;
  }
  .css-100u9pv-MuiDrawer-docked .MuiBox-root .css-k008qs {
    height: 0px !important;
    width: 100% !important;
  }

  .css-1ibjhk8 {
    top: 570px !important;
    position: absolute !important;
    left: 0px !important;
    padding: 0px !important;
    width: 100%;
  }

  .css-nukr7r .MuiDrawer-paper {
    width: 100% !important;
  }

  .css-k008qs {
    height: fit-content !important;
  }

  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    top: 0 !important;
  }
}

@media only screen and (max-width: 420px) {
  .css-1ibjhk8 {
    top: 550px !important;
  }
}

.modal-25 {
  /* max-width: 25% !important; */
  right: 0% !important;
  /* transform: translateX(100%) !important; */
  /* transition: transform 2s ease !important; */
  z-index: 99999;
  /* height: 100vh; */
  /* overflow: hidden; */
  position: absolute;
  /* left: 39%!important; */
}

.modal.show .modal-25 {
  transform: translateX(0) !important;
  margin-top: 0;
}

.modal-backdrop {
  filter: blur(1px) !important;
}

.modal {
  z-index: 999999;
}

.ss4-button-2:before {
  content: "";
  position: absolute !important;
  top: -5px !important;
  right: -5px !important;
  bottom: -5px !important;
  left: -5px !important;
  z-index: -1 !important;
  background: linear-gradient(
    90deg,
    #89efaf,
    #11a76f,
    #9ee373,
    #fbd78b
  ) !important;
  /* background: linear-gradient(90deg, #fbd78b, #b4c3fc, #eec1d8, #fbd78b); */
  background-size: 400% !important;
  border-radius: 40px !important;
  filter: blur(40px) !important;
  opacity: 0 !important;
  transition: 1.5s !important;
}

.ss4-button-2 {
  cursor: pointer !important;
  animation: animate 6s linear infinite;
  /* animation: animate 15s ease infinite; */
  padding: 15px 30px !important;
  text-align: center !important;
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  color: #000 !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  outline: none !important;
  border: 1px solid #fff;
  background-image: linear-gradient(
    90deg,
    #89efaf,
    #11a76f,
    #9ee373,
    #fbd78b
  ) !important;
  background-image: transparent !important;
  background-size: 400% !important;
  box-shadow: 0px 0px 12px 0px #91a2db !important;
  -webkit-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  transition: all 0.3s !important;
  transform: translate(0%, 0%) !important;
  overflow: hidden !important;
  display: inline-block !important;
}

.ss4-button-2 span {
  position: absolute !important;
}

.ss4-button-2 span:nth-child(1) {
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 2px !important;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(8, 20, 43, 0)),
    to(#2b5cfd)
  ) !important;
  background: linear-gradient(to left, rgba(8, 20, 43, 0), #2b5cfd) !important;
  -webkit-animation: 2s animateTop linear infinite !important;
  animation: 2s animateTop linear infinite !important;
}

.ss4-button-2 span:nth-child(2) {
  top: 0px !important;
  right: 0px !important;
  height: 100% !important;
  width: 2px !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(8, 20, 43, 0)),
    to(#000)
  ) !important;
  background: linear-gradient(to top, rgba(8, 20, 43, 0), #000) !important;
  -webkit-animation: 2s animateRight linear -1s infinite !important;
  animation: 2s animateRight linear -1s infinite !important;
}
.ss4-button-2 span:nth-child(3) {
  bottom: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 2px !important;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(8, 20, 43, 0)),
    to(#2b5cfd)
  ) !important;
  background: linear-gradient(to right, rgba(8, 20, 43, 0), #2b5cfd) !important;
  -webkit-animation: 2s animateBottom linear infinite !important;
  animation: 2s animateBottom linear infinite !important;
}

.ss4-button-2 span:nth-child(4) {
  top: 0px !important;
  left: 0px !important;
  height: 100% !important;
  width: 2px !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(8, 20, 43, 0)),
    to(#000)
  ) !important;
  background: linear-gradient(to bottom, rgba(8, 20, 43, 0), #000) !important;
  -webkit-animation: 2s animateLeft linear -1s infinite !important;
  animation: 2s animateLeft linear -1s infinite !important;
}

.offer-tex p {
  /* width: fit-content !important; */
  /* margin: 0px !important; */
  /* color: #000 !important; */
  /* padding: 0px 0px !important; */
  /* font-size: 20px !important; */
  /* font-weight: 100 !important; */
  /* font-family: "Tinos", serif !important; */
  /* text-align: center; */
}

/* td{
  vertical-align: top !important;
} */

/* @keyframes animate {
  0% {
    background-position: 0;
}

100% {
    background-position: 400%;
}
} */

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes animate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

td span {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.noBorderBottom {
  border-bottom: 0 !important;
}

.account-steps {
  display: flex;
  width: 85%;
  height: auto;
  margin: auto;
  padding-top: 11px;
  padding-bottom: 11px;
}

.step {
  height: 100%;
  width: 100%;
  background: #d6d6d6;
  border-radius: 12px;
  height: 12px;
  margin-right: 5px;
  margin-left: 10px;
}

.nomarginTop {
  margin-top: 0 !important;
}

.text-sm table {
  width: 100% !important;
}
.css-1ri6ub7-MuiPaper-root-MuiCard-root {
  max-width: 100% !important;
}

.terms-tex p {
  margin: 0px;
  color: #072d52;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  padding: 0px;
  font-family: "Plus Jakarta Sans", serif;
}

.terms-tex p a {
  margin: 0px;
  color: #009339;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  padding: 0px;
  text-decoration: underline;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  font-family: "Plus Jakarta Sans", serif;
}

.form-control {
  padding: 0.3rem !important;
}

.swalLink a ::before {
  box-shadow: none !important;
  outline: none !important;
}

.swalLink {
  box-shadow: none !important;
  outline: none !important;
}

/* common */
.ribbon-box {
  position: relative;
  max-width: 600px;
  width: 90%;
  height: 400px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #e0e40c;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #000;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  transform: rotate(180);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

.img-box {
  animation: bg-spin 3s linear infinite;
}

.img-box:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.tick {
  transform-origin: center;
  animation: grow 0.8s ease-in-out forwards;
}

@keyframes grow {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.printer-top {
  z-index: 1;
  border: 6px solid #666666;
  height: 6px;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  background: #333333;
}

.printer-bottom {
  z-index: 0;
  border: 6px solid #666666;
  height: 6px;
  border-top: 0;
  border-radius: 0 0 6px 6px;
  background: #333333;
}

.paper-container {
  position: relative;
  overflow: hidden;
  /* height: 467px; */
}

.paper {
  display: inline-block;
  /* background: #ffffff; */
  /* height: 447px; */
  /* min-width: 300px !important; */
  /* position: absolute; */
  /* z-index: 2; */
  /* margin: 0 12px; */
  margin-top: -12px;
  animation: print 1800ms cubic-bezier(0, 0.2, 0.5, 1);
  min-width: 100%;
  /* -moz-animation: print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9) ; */
}

.main-contents {
  /* margin: 0 12px; */
  padding: 24px;
  box-shadow: 0 0 6px 4px #e9ecef;
  /* animation: print 1800ms cubic-bezier(0, 0.2, 0.5, 1); */
}

.jagged-edge {
  position: relative;
  height: 20px;
  width: 100%;
  margin-top: -1px;
}

.jagged-edge-loading:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(
      45deg,
      transparent 33.333%,
      #ffffff 33.333%,
      #ffffff 66.667%,
      transparent 66.667%
    ),
    linear-gradient(
      -45deg,
      transparent 33.333%,
      #ffffff 33.333%,
      #ffffff 66.667%,
      transparent 66.667%
    );
  background-size: 16px 40px;
  background-position: 0 -20px;
}

.jagged-edge-failed:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(
      45deg,
      transparent 33.333%,
      #ea6a6a 33.333%,
      #f59e9e 66.667%,
      transparent 66.667%
    ),
    linear-gradient(
      -45deg,
      transparent 33.333%,
      #ea6a6a 33.333%,
      #f5a1a1 66.667%,
      transparent 66.667%
    );
  background-size: 16px 40px;
  background-position: 0 -20px;
}

.jagged-edge-success:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(
      45deg,
      transparent 33.333%,
      #5cad7f 33.333%,
      #509b6f 66.667%,
      transparent 66.667%
    ),
    linear-gradient(
      -45deg,
      transparent 33.333%,
      #5cad7f 33.333%,
      #509b6f 66.667%,
      transparent 66.667%
    );
  background-size: 16px 40px;
  background-position: 0 -20px;
}

.success-icon {
  text-align: center;
  font-size: 48px;
  height: 72px;
  background: #359d00;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin: 16px auto;
  color: #fff;
}
.fail-icon {
  text-align: center;
  font-size: 48px;
  height: 72px;
  background: #f13b3b;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin: 16px auto;
  color: #fff;
}

.success-title {
  font-size: 22px;
  text-align: center;
  color: #666;
  font-weight: bold;
  margin-bottom: 16px;
}

.success-description {
  font-size: 15px;
  line-height: 21px;
  color: #999;
  text-align: center;
  margin-bottom: 15px;
}

.circle {
  transform-origin: center;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash 1s linear;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@keyframes print {
  0% {
    transform: translateY(-90%);
  }
  100% {
    transform: translateY(0%);
  }
}
@-webkit-keyframes print {
  0% {
    -webkit-transform: translateY(-90%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
@-moz-keyframes print {
  0% {
    -moz-transform: translateY(-90%);
  }
  100% {
    -moz-transform: translateY(0%);
  }
}
@-ms-keyframes print {
  0% {
    -ms-transform: translateY(-90%);
  }
  100% {
    -ms-transform: translateY(0%);
  }
}

.v {
  color: rgb(240, 98, 4);
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.e {
  color: rgb(240, 98, 4);
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.4s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.r {
  color: rgb(240, 98, 4);
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.6s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.i {
  color: rgb(240, 98, 4);
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.8s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.f {
  color: rgb(240, 98, 4);
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.n {
  color: rgb(240, 98, 4);
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.g {
  color: rgb(240, 98, 4);
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.4s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.y {
  color: rgb(240, 98, 4);
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.5s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.d1 {
  color: rgb(240, 98, 4);
  opacity: 0;
  animation: pass1 2s ease-in-out infinite;
  animation-delay: 1.6s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.d2 {
  color: rgb(240, 98, 4);
  opacity: 0;
  animation: pass1 2s ease-in-out infinite;
  animation-delay: 1.8s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

@keyframes pass {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pass1 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.github-fork-ribbon {
  top: 20px !important;
  left: -68px !important;
}

.github-fork-ribbon a {
  border: none !important;
}

.css-100u9pv-MuiDrawer-docked .MuiDrawer-paper {
  width: 100% !important;
}

.css-h4y409-MuiList-root {
  padding-bottom: 0px !important;
}
.MuiDrawer-paperAnchorDockedLeft .css-1l8j5k8 {
  width: 100% !important;
}

.css-1ontqvh {
  padding-bottom: 0px !important;
}

.vefs-milestone-wrapper .milestone-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  height: 50px;
}
.vefs-milestone-wrapper .milestone-container .chart-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 1 50%;
}
.vefs-milestone-wrapper .milestone-container .chart-container .line-container {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 17.5px;
}
.vefs-milestone-wrapper
  .milestone-container
  .chart-container
  .line-container
  .line {
  align-self: center;
  position: absolute;
  top: 8.75px;
  transform: translateY(-50%);
  order: 1;
  width: 100%;
  height: 5px;
  background-color: #cccccc;
  background-color: rgba(204, 204, 204, 0.5);
}
.vefs-milestone-wrapper
  .milestone-container
  .chart-container
  .line-container
  .line.left {
  order: 0;
  background-color: #bbbbbb;
}
.vefs-milestone-wrapper .milestone-container .chart-container .dot-container {
  position: absolute;
  height: 17.5px;
  width: 100%;
  align-items: center;
  display: flex;
}
.vefs-milestone-wrapper
  .milestone-container
  .chart-container
  .dot-container
  .dot {
  position: absolute;
  width: 17.5px;
  height: 17.5px;
  border-radius: 50%;
  background-color: #cccccc;
  transform: translateX(-50%);
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper
  .milestone-container
  .chart-container
  .dot-container
  .dot.completed {
  background-color: #bbbbbb;
}
.vefs-milestone-wrapper .milestone-container .label-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  flex: 1 50%;
}
.vefs-milestone-wrapper .milestone-container .label-container .label {
  position: relative;
  font-size: 0.7rem;
  font-weight: 600;
  color: #cccccc;
}
.vefs-milestone-wrapper .milestone-container .label-container .label.colored {
  color: #bbbbbb;
}
.vefs-milestone-wrapper .milestone-container .milestones {
  position: absolute;
  transform: translate(-50%, 0);
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__0 {
  left: 1%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__0
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}

.vefs-milestone-wrapper .label-container .milestones.milestone__0 .colored {
  color: #ffbc42;
  transition: all 0.25s ease-out;
}

.vefs-milestone-wrapper .milestone-container .milestones.milestone__2 {
  left: 2%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__2
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__3 {
  left: 3%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__3
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__4 {
  left: 4%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__4
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__5 {
  left: 5%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__5
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__6 {
  left: 6%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__6
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__7 {
  left: 7%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__7
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__8 {
  left: 8%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__8
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__9 {
  left: 9%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__9
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__10 {
  left: 10%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__10
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__11 {
  left: 11%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__11
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__12 {
  left: 12%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__12
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__13 {
  left: 13%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__13
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__14 {
  left: 14%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__14
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__15 {
  left: 15%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__15
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__16 {
  left: 16%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__16
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__17 {
  left: 17%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__17
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__18 {
  left: 18%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__18
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__19 {
  left: 19%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__19
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__20 {
  left: 20%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__20
  .dot.colored {
  background-color: #ffbc42;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__21 {
  left: 21%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__21
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__22 {
  left: 22%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__22
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__23 {
  left: 23%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__23
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__24 {
  left: 24%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__24
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__25 {
  left: 25%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__25
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__26 {
  left: 26%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__26
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__27 {
  left: 27%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__27
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__28 {
  left: 28%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__28
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__29 {
  left: 29%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__29
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__30 {
  left: 30%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__30
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__31 {
  left: 31%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__31
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__32 {
  left: 32%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__32
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__33 {
  left: 33%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__33
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__34 {
  left: 34%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__34
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__35 {
  left: 35%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__35
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .label-container .milestones.milestone__35 .colored {
  color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__36 {
  left: 36%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__36
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__37 {
  left: 37%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__37
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__38 {
  left: 38%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__38
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__39 {
  left: 39%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__39
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__40 {
  left: 40%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__40
  .dot.colored {
  background-color: #d81159;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__41 {
  left: 41%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__41
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__42 {
  left: 42%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__42
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__43 {
  left: 43%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__43
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__44 {
  left: 44%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__44
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__45 {
  left: 45%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__45
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__46 {
  left: 46%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__46
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__47 {
  left: 47%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__47
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__48 {
  left: 48%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__48
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__49 {
  left: 49%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__49
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__50 {
  left: 50%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__50
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__51 {
  left: 51%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__51
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__52 {
  left: 52%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__52
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__53 {
  left: 53%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__53
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__54 {
  left: 54%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__54
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__55 {
  left: 55%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__55
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__56 {
  left: 56%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__56
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__57 {
  left: 57%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__57
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__58 {
  left: 58%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__58
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__59 {
  left: 59%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__59
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__60 {
  left: 60%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__60
  .dot.colored {
  background-color: #8f2d56;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__61 {
  left: 61%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__61
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__62 {
  left: 62%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__62
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__63 {
  left: 63%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__63
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__64 {
  left: 64%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__64
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__65 {
  left: 65%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__65
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__66 {
  left: 66%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__66
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__67 {
  left: 67%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__67
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__68 {
  left: 68%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__68
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__69 {
  left: 69%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__69
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__70 {
  left: 70%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__70
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .label-container .milestones.milestone__70 .colored {
  color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__71 {
  left: 71%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__71
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__72 {
  left: 72%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__72
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__73 {
  left: 73%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__73
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__74 {
  left: 74%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__74
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__75 {
  left: 75%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__75
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__76 {
  left: 76%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__76
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__77 {
  left: 77%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__77
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__78 {
  left: 78%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__78
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__79 {
  left: 79%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__79
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__80 {
  left: 80%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__80
  .dot.colored {
  background-color: #218380;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__81 {
  left: 81%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__81
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__82 {
  left: 82%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__82
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__83 {
  left: 83%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__83
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__84 {
  left: 84%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__84
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__85 {
  left: 85%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__85
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__86 {
  left: 86%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__86
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__87 {
  left: 87%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__87
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__88 {
  left: 88%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__88
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__89 {
  left: 89%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__89
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__90 {
  left: 90%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__90
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__91 {
  left: 91%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__91
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__92 {
  left: 92%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__92
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__93 {
  left: 93%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__93
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__94 {
  left: 94%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__94
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__95 {
  left: 95%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__95
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__96 {
  left: 96%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__96
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__97 {
  left: 97%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__97
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__98 {
  left: 98%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__98
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__99 {
  left: 99%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__99
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}
.vefs-milestone-wrapper .milestone-container .milestones.milestone__100 {
  left: 100%;
}
.vefs-milestone-wrapper
  .milestone-container
  .milestones.milestone__100
  .dot.colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}

.vefs-milestone-wrapper .label-container .milestones.milestone__100 .colored {
  background-color: #73d2de;
  transition: all 0.25s ease-out;
}

/*===============dashboard style=============*/

.left-side-bar {
  position: relative;
  padding: 5px 20px 5px 20px;
  width: 100%;
  height: 880px;
  border-radius: 8px;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.left-side-bar .profiles {
  position: relative;
  display: inline;
  margin-top: 20px;
  text-align: center;
  align-content: center;
  width: 100%;
}

.left-side-bar .profiles img {
  position: relative;
  margin-top: 20px;
  display: inline;
  text-align: center;
  width: 80px;
  height: 80px;
  border-radius: 50px;
}
.left-side-bar .profiles h4 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
.left-side-bar .profiles h5 {
  font-size: 13px;
  font-weight: 300;
  color: #6d6d6d;
}

.left-side-bar .profiles p {
  font-size: 12px;
  font-weight: 300;
  color: #6d6d6d;
}

.left-side-bar .last-login {
  position: relative;
  display: inline;
  margin-top: 20px;
  text-align: left;
  align-content: left;
  width: 100%;
}

.left-side-bar .last-login img {
  position: relative;
  margin-top: 20px;
  display: inline;
  text-align: center;
  width: 80px;
  height: 80px;
  border-radius: 50px;
}
.left-side-bar .last-login h4 {
  font-size: 13px;
  font-weight: 300;
  color: #6d6d6d;
}
.left-side-bar .last-login span {
  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
  color: #6d6d6d;
}

.left-side-bar .last-login p {
  font-size: 12px;
  font-weight: 300;
  color: #6d6d6d;
}
.left-side-bar .activitys {
  position: relative;
  display: block;
  margin-top: 20px;
  text-align: left;
  align-content: left;
  max-height: 35%;
  overflow: auto;
}
.left-side-bar .activitys h3 {
  font-size: 14px;
  font-weight: 600;
  color: #6d6d6d;
}
.act-col {
  position: relative;
  display: flex;
}
.act-col .vl {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 50%;
  height: 35px;
  border-left: 4px dotted #eeecec;
}
.act-col .left-c {
  position: relative;
  display: block;
  float: left;
  width: 60%;
  margin-bottom: 5px;
}

.act-col .left-c span {
  font-size: 12px;
  font-weight: 300;
  color: #d2cccc;
  word-wrap: break-word;
}
.act-col .right-c {
  position: relative;
  display: block;
  float: right;
  width: 40%;
  margin-bottom: 5px;
}
.act-col .right-c span {
  font-size: 12px;
  font-weight: 300;
  padding-left: 10px;
  color: #d2cccc;
}

.left-side-bar .activitys p {
  font-size: 12px;
  font-weight: 300;
  color: #6d6d6d;
}

.left-side-bar .last-months {
  position: relative;
  display: block;
  margin-top: 20px;
  text-align: left;
  align-content: left;
  width: 100%;
  max-height: 25%;
  overflow: auto;
}
.left-side-bar .last-months h3 {
  font-size: 13px;
  font-weight: 300;
  color: #d2cccc;
}
.act-cols {
  position: relative;
  display: flex;
}
.act-cols .vl {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 50%;
  height: 35px;
  border-left: 4px dotted #eeecec;
}
.act-cols .left-c {
  position: relative;
  display: block;
  float: left;
  width: 60%;
  margin-bottom: 5px;
}

.act-cols .left-c span {
  font-size: 12px;
  font-weight: 300;
  color: #d2cccc;
  word-wrap: break-word;
}
.act-cols .right-c {
  position: relative;
  display: block;
  float: right;
  width: 40%;
  margin-bottom: 5px;
}
.act-cols .right-c span {
  font-size: 12px;
  font-weight: 300;
  padding-left: 10px;
  color: #d2cccc;
}

.left-side-bar .activitys p {
  font-size: 12px;
  font-weight: 300;
  color: #6d6d6d;
}

.card-rs {
  position: relative;
  margin: 10px;
  padding: 20px;
  height: 135px;
  border-radius: 8px;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.card-rs .card-content {
  margin: 10px;
}
.card-rs .card-content h3 {
  position: absolute;
  display: inline-block;
  margin-top: 10px;
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  text-align: left !important;
  line-height: 30px;
  color: #000;
}

.card-rs .card-content img {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.card-rs .card-content h4 {
  position: absolute;
  display: inline-block;
  margin-top: 90px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #000;
}
.card-rs .card-content h4 span {
  font-size: 18px;
  font-weight: 600;
  color: #6d6d6d !important;
}
.card-rs .card-content p {
  position: absolute;
  display: inline-block;
  margin-top: 150px;
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  color: #6d6d6d;
}

/* .order-icons{
  padding: 4px;
    background: linear-gradient(23.01deg, #2b5cfd 14.9%, #1d3faf 85.1%);
    border-radius: 32%;
    color: white;
} */

/*==============bread-header===============*/
.sbreadcrumb {
  margin: 2%;
  width: 96%;
  border-radius: 5px;
  padding: 15px;
  background-image: linear-gradient(-120deg, #fdfbfb 0%, #f5f5f5 100%);
}

.sbreadcrumb .lcard {
  position: relative;
  float: left;
}
.sbreadcrumb .lcard img {
  position: relative;
  display: inline;
  text-align: center;
  width: 80px;
  height: 80px;
  border-radius: 50px;
}
.sbreadcrumb .lcard span {
  font-size: 15px;
  margin-left: 20px;
}
.s-color {
  font-size: 12px !important;
  font-weight: 300;
  color: #818181;
}
/*.sbreadcrumb .lcard h5 {
	font-size: 13px;
	font-weight: 300;
	color: #6d6d6d;
}*/
.sbreadcrumb .rcard {
  text-align: right !important;
  position: relative;
  float: right !important;
}
/*==============order style================*/
.nav-but-left {
  display: block;
  text-align: left;
  margin: 5px 4px;
  padding: 5px 10px;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  border-radius: 3px;
}

.nav-but-left.active {
  border-left: 1px solid #b6dbfd;
  border-top: 1px solid #b6dbfd;
  border-right: 1px solid #b6dbfd;
  border-bottom: 5px solid #0736d0;
}
.nav-but-left:hover {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

/*==================modal paid card==================*/
.popup-link {
  display: flex;
  flex-wrap: wrap;
}

.popup-link a {
  background: #333;
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  margin: 20px;
  text-decoration: none;
}

.popup-container {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 17, 17, 0.61);
  display: flex;
  align-items: center;
}
.popup-content {
  background-color: transparent;
  margin: auto;
  padding: 20px;
  width: 400px;
}
.popup-content p {
  font-size: 17px;
  padding: 10px;
  line-height: 20px;
}
.popup-content .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  background: none;
  padding: 0;
  margin-right: 10px;
  text-decoration: none;
  transition: color 0.2s, background-color 0.2s, border 0.2s, box-shadow 0.2s;
}

.popup-content .close:hover {
  color: #333;
}

.popup-content span:hover,
.popup-content span:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.popup-container:target {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.show-popup {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}

.popup-container h3 {
  margin: 10px;
}
/*End popup styles*/

/* Additional styles */
.popup-style-2 {
  transform: scale(0.3);
}

.popup-style-2:target {
  transform: scale(1);
}

.popup-style-3 {
  left: 100%;
}

.popup-style-3:target {
  left: 0;
}

.popup-style-4 {
  transform: rotate(180deg);
}

.popup-style-4:target {
  transform: rotate(0deg);
}

.popup-style-5 {
  top: 100%;
}

.popup-style-5:target {
  top: 0;
}

.popup-style-6 {
  transform: scale(15.3);
}

.popup-style-6:target {
  transform: scale(1);
}

.popup-style-7 {
  transform: skewY(180deg);
  transition: all 0.7s ease-in-out;
}

.popup-style-7:target {
  transform: skewY(0deg);
}
/*======================popup===================*/
.pricing-tables {
  background: #fff;
  box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, 0.15);
  padding: 2rem;
  border-radius: 4px;
  transition: 0.3s;
}

.pricing-tables:hover {
  box-shadow: 0px 1px 10px -4px rgba(0, 0, 0, 0.15);
}

.pricing-tables .pricing-labels {
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
}

.pricing-tables h2 {
  color: #3b3b3b;
  font-size: 24px;
  font-weight: 500;
}

.pricing-tables h5 {
  color: #939191;
  font-size: 14px;
  font-weight: 300;
}

.pricing-tables .pricing-features {
  margin-top: 2rem;
}

.pricing-tables .pricing-features .feature {
  font-size: 15px;
  line-height: 32px;
  margin: 2px;
  color: #111;
}

.pricing-tables .pricing-features .feature span {
  display: inline-block;
  float: right;
  color: #3b3b3b;
  font-weight: 500;
}
.pricing-tables .pricing-features .feature span .sucesss {
  background-color: #92fe9d;
  border: 1px solid #92fe9d;
}

.pricing-tables .pricing-features .feature .amounts {
  font-size: 14px;
  color: #000;
}
.pricing-tables .pricing-features .feature .borderr {
  padding: 0px 5px;
  border-radius: 3px;
  background-color: #d1f8ec;
  /*border: 1px solid #92fe9d;*/
}
.pricing-tables .pricing-features .feature .borders {
  padding: 0px 5px;
  border-radius: 3px;
  border: 1px solid #00c9ff;
}
.pricing-tables .price-tag {
  margin-top: 2rem;
  text-align: center;
  font-weight: 500;
}

.pricing-tables .price-tag .symbol {
  font-size: 24px;
}

.pricing-tables .price-tag .amount {
  letter-spacing: -2px;
  font-size: 64px;
}

.pricing-tables .price-tag .after {
  color: #3b3b3b;
  font-weight: 500;
}

.pricing-tables .price-button {
  display: block;
  color: #fff;
  margin-top: 2rem;
  padding: 0.75rem;
  border-radius: 2px;
  text-align: center;
  font-weight: 500;
  transition: 0.3s;
}

.pricing-tables .price-button:hover {
  text-decoration: none;
}

.purple .pricing-label {
  background: #cad2ff;
  color: #627afe;
}

.purple .price-tag {
  color: #627afe;
}

.purple .price-button {
  background: #627afe;
}

.purple .price-button:hover {
  background: #546dfe;
}

.turquoise .pricing-labels {
  /*background: #b9edee;*/
  font-size: 16px;
  font-weight: 600;
  background-image: linear-gradient(to right, #92fe9d 0%, #00c9ff 100%);
  color: #fff;
}

.turquoise .price-tag {
  color: #44cdd2;
}

.turquoise .price-button {
  background: #44cdd2;
}

.turquoise .price-button:hover {
  background: #2dbcc4;
}

.red .pricing-label {
  background: #ffc4c4;
  color: #ff5e5e;
}

.red .price-tag {
  color: #ff5e5e;
}

.red .price-button {
  background: #ff5e5e;
}

.red .price-button:hover {
  background: #f23c3c;
}

.products-row {
  background-color: #fff;
  transition: transform 0.5s;
}

.products-row:hover {
  transform: scale(1.1);
}

small {
  font-size: 70% !important;
}

.accordion-button {
  padding: 0 !important;
}

.accordion-body {
  padding: 0 !important;
}

.paymentPending a {
  /* color: #fff !important; */
  display: block;
  border: none;
  padding: 20px 20px 20px 20px;
  font-size: 18px;
  font-weight: 500;
  /* background-image: linear-gradient(-225deg, #f26e71 0%, #f8dcdc 100%); */
  /* background-image: linear-gradient(-225deg, #74c3db 0%, #0e91ce 48%, #45D4FB 100%); */
  border-color: #fcbf0b;
  border-left: 5px solid #fcbf0b !important;
  border: 1px solid #fcbf0b;
  position: relative;
  /* color: #fff; */
  box-shadow: none;
  text-decoration: none;
}

.paymentPending a:after,
.paymentPending a.collapsed:after {
  content: "\2303";
  font-family: fontawesome;
  text-align: center;
  position: absolute;
  right: -20px;
  top: 10px;
  color: #fff;
  background-color: #fcbf0b;

  border: 5px solid #fff;
  font-size: 15px;
  width: 40px;
  height: 40px;
  line-height: 30px;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}

.swiper-pagination {
  position: relative !important;
  margin-top: 20px !important;
}

.fixedBadge .css-x3w4s2-MuiBadge-badge {
  font-size: xx-small;
  padding: 0px;
  min-width: 14px;
  height: 14px;
  border-radius: 50%;
}

.addressInput {
  padding: 0.4rem !important;
  padding-left: 0.7rem !important;
  border-radius: 0.1rem !important;
  font-size: 12px;
}

.reduceModalSize .modal-lg {
  --bs-modal-width: 700px !important;
}

.icon-box {
  color: #fff;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -45px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  z-index: 99999999;
  background: #82ce34;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.human-heart {
  margin: 5em;
  animation: 0.8s infinite beatHeart;
}

@keyframes beatHeart {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.css-o4b71y-MuiAccordionSummary-content {
  margin: 12px 0 !important;
}

.MuiAccordionSummary-content .Mui-expanded {
  margin: 0;
}

.nav-pills .nav-link {
  text-align: left;
  border-radius: 0;
  font-weight: 600;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #2b5cfd;
  background-color: #e6ebfc;
}
.nav-link i {
  width: 25px;
  text-align: center;
}

.client-details {
  text-align: left;
}

.client-details p {
  color: #4aace3;
}

.bg-light-info {
  background: #d7fbff;
  color: #00bcd4;
  border: 1px solid #d7fbff;
  cursor: pointer;
}

.bg-light-info:hover {
  background: #00bcd4;
  color: #ffffff;
  border: 1px solid #00bcd4;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .profile-container {
    justify-content: center;
  }
  .client-details {
    text-align: center;
  }
}

.gradient-bg{
  background: radial-gradient(49.98% 52.41% at 44.18% 118.79%,rgba(252,120,136,.2) 0,rgba(252,82,102,0) 100%),radial-gradient(38.18% 50.85% at 43.11% -7.92%,rgba(50,138,245,.2) 0,rgba(50,138,245,0) 100%),radial-gradient(32.39% 65.68% at 101.26% 36.34%,rgba(98,57,198,.2) 0,rgba(98,57,198,0) 100%),radial-gradient(25.84% 56.13% at -7.92% 73.21%,rgba(192,16,239,.2) 0,rgba(192,16,239,0) 100%),#f9f8f9;
}